body {
  counter-reset: page;
}

@media print {
  @page {
    counter-increment: page;
  }
  .footer {
    page-break-inside: avoid;
  }

  .page-number:before {
    content: counter(page);
  }
}
