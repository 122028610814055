.p-steps .p-steps-item.p-highlight .p-steps-number {
  background-color: $primary;
  color: $white;
}

.p-primary-btn {
  background-color: $primary;
  border-color: $primary;

  &:hover {
    background-color: darken($color: $primary, $amount: 10) !important;
    border-color: darken($color: $primary, $amount: 10) !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem $sLightYellow !important;
  }
}
.p-primary-cld-btn button {
  background-color: $primary;
  border-color: $primary !important;
  &:hover {
    background-color: darken($color: $primary, $amount: 10) !important;
    border-color: $primary !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem $sLightYellow !important;
    border-color: $primary !important;
  }
}
.background-clr-primary {
  background-color: $primary !important;
}
.calender input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 100%;
  &:hover {
    border-color: darken($color: $sYellow, $amount: 10) !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem $sLightYellow !important;
  }
}

.p-sales-report-btn {
  background-color: black;
  border-color: white;
  width: 5rem;
  height: 1.875rem;
  font-size: 0.725rem; /* or any other size you want */
  & :hover {
    background-color: darken($color: grey, $amount: 10) !important;
    border-color: darken($color: white, $amount: 10) !important;
  }
  & :focus {
    box-shadow: 0 0 0 0.2rem white !important;
  }

  i {
    font-size: 0.425rem; /* or any other size you want */
  }
}
.p-datatable-tbody tr {
  background: black !important;
}

.p-secondary-btn {
  background-color: $secondary;
  border-color: $secondary;

  &:hover {
    background-color: darken($color: $secondary, $amount: 10) !important;
    border-color: darken($color: $secondary, $amount: 10) !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem lighten($color: $secondary, $amount: 50) !important;
  }
}

.p-black-btn {
  background-color: $black;
  border-color: $black;

  &:hover {
    background-color: darken($color: $black, $amount: 40) !important;
    border-color: darken($color: $black, $amount: 40) !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem $lightBlack !important;
  }
}

.p-multiselect-filter {
  width: 100% !important;
  &:hover {
    border-color: darken($color: $sYellow, $amount: 10) !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem $sLightYellow !important;
  }
}

.p-primary-highlight-btn {
  background-color: $primary;
  border-color: $primary;

  &:hover {
    background-color: darken($color: $primary, $amount: 10) !important;
    border-color: darken($color: $primary, $amount: 10) !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem $sLightYellow !important;
  }
}

.p-red-btn {
  background-color: $sRed;
  border-color: $sRed;

  &:hover {
    background-color: darken($color: $sRed, $amount: 10) !important;
    border-color: darken($color: $sRed, $amount: 10) !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem lighten($color: $sRed, $amount: 50) !important;
  }
}

.p-primary-input {
  &:hover {
    border-color: darken($color: $sYellow, $amount: 10) !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem $sLightYellow !important;
  }
}

[dir="rtl"] .p-password i {
  left: 3rem; /* Adjust the value as needed */
  right: auto;
}

.p-breadcrumb {
  padding: 0 !important;
  background: inherit !important;
  border: none !important;
  margin-bottom: 0.25rem !important;
}

.p-menu .p-menu-separator {
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.profile-menu .p-menuitem-link {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

[data-pc-name="paginator"] {
  justify-content: flex-end !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  background-color: #f8fafc !important;
  [data-pc-name="dropdown"] {
    width: 7rem !important;
  }
  [data-pc-section="left"] {
    margin-right: auto !important;
  }
}
